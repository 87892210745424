/*
###########
# Session #
###########

-   **Inputs**: 
    - `currentTheme`
      - Object for design standardization
    - `setTheme`
      - React hook to update the theme
-   **Purpose**: This is the parent component for `Experience`
-   **To do**:
    -   Backend communication
    -   Add in editor
- **Issues**
    - Terminal does not respect the height of the parent stack item
-   **Note**: None
*/

// External Components

import React, { useContext, useRef, useState } from "react";

//import './i18n'; // Language managment
//import { useTranslation } from 'react-i18next';
import { useBoolean } from "@fluentui/react-hooks"; // re
import { Stack } from "@fluentui/react/lib/Stack";

// CloudShell Components
import DownloadDialog from "./Dialog/Download";
import PortDialog from "./Dialog/PortPreview";
import ResetUserDialog from "./Dialog/ResetUserSettings";
import RestartDialog from "./Dialog/Restart";
import SwitchShellTypeDialog from "./Dialog/SwitchShellType";
import Toolbar from "./Toolbar";
import CSTerminal from "./Terminal";
import { Theme } from "@fluentui/react";
import { DownloadFileLinkInfo, FilesInfo, TerminalRef } from "../../common/types";
import { UserSettingsContext } from "../DataProvider/UserSettingsProvider";
import { FileStatus, LocalStorageKey, UserSettingsActionType, maxUploadSize, ShellType, SessionType, FontSize, PostMessageHelperType, RequestLoggerEnum, HttpMethod } from "../../common/consts";

import { useAccessTokenContext, useParentMessageContext } from "../DataProvider/EventProvider";
import axios from "axios";
import UploadDialog from "./Dialog/Upload";
import TermTimeoutDialog from "../Error/TermTimeout";
import { addToLocalStorage, getAndRemoveSessionConsoleShellType, saveSessionConsoleShellType, getFromLocalStorage, postMessageHelper, getARMEndpoint } from "../Util/Utilities";
import FileManager from "../Util/FileManager";
import ErrorDialog from "../Error/Error";
import SwitchToV1 from "../Onboarding/Dialog/SwitchToV1";
import { useTranslation } from "react-i18next";
import { useARMHelper } from "../Util/useARMHelper";
import ConfirmReloadDialog from "./Dialog/ConfirmReload";
import EditorWrapper from "./Editor/EditorWrapper";
import { EditorVisibleContext } from "./Editor/EditorVisibleContext";
import { useLogger } from "../Util/Logger";
import { CorrelationIdContext } from "../DataProvider/IdProviders";

// Interface
interface SessionProps {
    currentTheme: Theme;
    setTheme: (newTheme: Theme) => void;
    setVerifyStorageAccountError: (verifyStorageAccountError: string) => void;
}

let filesToBeUploaded: File[] = [];
let isUploadsInProgress = false;
let sendNewUploadRequestWhilePreivousDialogOpen = false;

const Session = (sessionProps: SessionProps) => {
    // App Code starts here

    const { t } = useTranslation();
    const logger = useLogger(getARMEndpoint(), {});

    // view elements
    const [hideDownloadDialog, { toggle: toggleDownloadDialog }] = useBoolean(true); // Download dialog view
    const [hideDownloadClickLinkDialog, { toggle: toggleDownloadClickLinkDialog, setTrue: setDownloadClickLinkDialogTrue, setFalse: setDownloadClickLinkDialogFalse}] = useBoolean(true); // Download click link dialog view
    const [hidePortDialog, { toggle: togglePortDialog }] = useBoolean(true); // Port dialog view
    const [hideRestartDialog, { toggle: toggleRestartDialog }] = useBoolean(true); // Restart dialog view
    const [hideResetUserDialog, { toggle: toggleResetUserDialog }] = useBoolean(true); // Reset User Settings dialog
    const [hideSwitchShellTypeDialog, { toggle: toggleSwitchShellTypeDialog }] = useBoolean(true); // Switch dialog
    const [hideConfirmReloadDialog, { toggle: toggleConfirmReloadDialog }] = useBoolean(true); // Confirm deload dialog
    const [hideTermTimeoutDialog, { toggle: toggleTermTimeoutDialog }] = useBoolean(true); // Terminal timeout dialog
    const [hideErrorDialog, { toggle: toggleErrorDialog }] = useBoolean(true); // error dialog
    const [isFullScreen, { toggle: toggleIsFullScreen }] = useBoolean(false);
    const [isSwitchToV1Open, { setTrue: showSwitchToV1, setFalse: hideSwitchToV1 }] = useBoolean(false);
    const [switchToV1Method, setSwitchToV1Method] = useState("");
    
    const { accessToken } = useAccessTokenContext();
    const { token, cert, commands, commandShellType } = useParentMessageContext();
    const language = useTranslation().i18n.language;
    
    //variables for terminal
    const terminalRef = useRef<TerminalRef>(null);
    const [terminalVisible, setTerminalVisible] = useState(true);
    const [isTerminalFocused, setIsTerminalFocused] = useState(false);

    //variables for upload progress bar
    const [percentComplete, setPercentComplete] = useState<number>(0);
    const [currentFileIndex, setCurrentFileIndex] = useState<number>(0);
    const [uploadInProgressTotalCount, setUploadInProgressTotalCount] = useState<number>(0);
    const [keepUploadInProgressDialogOpen, setKeepUploadInProgressDialogOpen] = useState<boolean>(true);
    const [hideUploadInProgressDialog, { setTrue: setHideUploadInProgressDialogTrue, setFalse: setHideUploadInProgressDialogFalse }] = useBoolean(true);

    //variables for upload complete dialog
    let tempFileCompleteStatusList: Array<FilesInfo> = [];
    const [fileCompleteStatusList, setFileCompleteStatusList] = useState<Array<FilesInfo>>([]); //record the file name and file complete status
    const [uploadDestination, setUploadDestination] = useState<string>("");
    const [hideUploadDialog, { toggle: toggleUploadDialog, setTrue: setHideUploadDialogTrue, setFalse: setHideUploadDialogFalse }] = useBoolean(true); // upload complete dialog view
    const [callFileUploadFunction, setCallFileUploadFunction] = useState<boolean>(false);

    //variables for upload error dialog
    let tempSucceedCount = 0;
    const [uploadSucceedCount, setUploadSucceedCount] = React.useState<number>(0);
    const [uploadHasErrors, setUploadHasErrors] = React.useState<boolean>(false);
    const [fileTooLargeErrorExists, setFileTooLargeErrorExists] = React.useState<boolean>(false);
    const [allFilesTooLarge, setAllFilesTooLarge] = React.useState<boolean>(true);

    //variables for download
    const [downloadFileLinkInfoList, setDownloadFileLinkInfoList] = useState<Array<DownloadFileLinkInfo>>([]);

    // Load Elements
    const { userSettingsState, userSettingsDispatch} = React.useContext(UserSettingsContext);

    // Shared states
    const [currentFontSize, setFontSize] = React.useState<string>(userSettingsState.properties.terminalSettings.fontSize.toLowerCase() || FontSize.Medium);
    const [currentFontStyle, setFontStyle] = React.useState<string>(userSettingsState.properties.terminalSettings.fontStyle.toLowerCase());
    const setUpCurrentPort = () => {
        const port = getFromLocalStorage(LocalStorageKey.CurrentOpenPort);
        return !port || port === "null" ? 0 : parseInt(port);
    }
    const [currentPort, setPort] = React.useState<number>(() => setUpCurrentPort());
    
    const [consoleUri, setConsoleUri] = React.useState("");
    const [termId, setTermId] = React.useState<string | null>(null);
    const { correlationId } = useContext(CorrelationIdContext);
    const [userRootDirectory, setUserRootDirectory] = React.useState("");
    const setUpShellType = () => {
        return getAndRemoveSessionConsoleShellType() || userSettingsState.properties.preferredShellType || ShellType.Bash;
    }
    const [currentShellType, setShellType] = React.useState<ShellType>(() => setUpShellType());
    const [isToolbarFeatureDisabled, setIsToolbarFeatureDisabled] = React.useState<boolean>(true);
    const { updateUserSettings } = useARMHelper();
    const [isRestarting, setIsRestarting] = useState<boolean>(false);
    const [editorHeight, setEditorHeight] = useState<number>(100);
    const [editorVisible, setEditorVisible] = useState(false);
    const [fileName, setFileName] = useState<string>(t("untitledFileHeader"));
    const [localFilepath, setLocalFilepath] = useState<string>("");
    const [openEditorWithFile, setOpenEditorWithFile] = useState<boolean>(false);
    
    // Callback functions
    const ChangeShellType = () => {
        switch (currentShellType) {
            case ShellType.Bash:
                setShellType(ShellType.PowerShell);
                saveSessionConsoleShellType(ShellType.PowerShell);
                break;
            case ShellType.PowerShell:
                setShellType(ShellType.Bash);
                saveSessionConsoleShellType(ShellType.Bash);
                break;
            default:
                saveSessionConsoleShellType(ShellType.Bash);
                break;
        }
        toggleSwitchShellTypeDialog(); // hide dialog window
    };

    const setTerminalFocused = () => {
        terminalRef.current?.focus();
    }

    const reloadTerminal = () => {
        toggleConfirmReloadDialog();
        terminalRef.current?.reloadTerminal();
    }
    
    //called after the upload dialog is dismissed and the animation is complete
    const handleUploadCompleteDismissed = () => {
        setUploadHasErrors(false);
        setFileTooLargeErrorExists(false);
        setAllFilesTooLarge(true);
        setUploadInProgressTotalCount(filesToBeUploaded.length);
        setFileCompleteStatusList([]);
        tempSucceedCount = 0;
        setUploadSucceedCount(0);
        if (sendNewUploadRequestWhilePreivousDialogOpen) {
            setCallFileUploadFunction(true); //upload functions inside React.useEffect will be called
        }
    }

    React.useEffect(() => {
        if (callFileUploadFunction) {
            setUploadInProgressTotalCount(filesToBeUploaded.length);
            uploadFiles();
        }
    }, [callFileUploadFunction])

    React.useEffect(() => {
        if (consoleUri) {
            const targetUri = consoleUri + '/accessToken';
            const start = Date.now();
            const newToken = { token: token.tokenValue };

            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': accessToken,
                'Accept-Language': language
            };

            axios.post(targetUri, JSON.stringify(newToken), { headers })
                .then(response => {
                    logger.clientRequest(RequestLoggerEnum.Post_Token, {}, Date.now() - start, HttpMethod.Post, targetUri, "", "", "", 0, response.status, correlationId);
                    
                }).catch(error => {
                    logger.clientRequest(RequestLoggerEnum.Post_Token, {}, Date.now() - start, HttpMethod.Post, targetUri, "", "", "", 0, error.response.status, correlationId);
                    console.error('ACC.POST.TOKEN Something went wrong!', error);
                });
        }
    }, [token]);

    React.useEffect(() => {
        if(commands && commandShellType != currentShellType) {
            toggleSwitchShellTypeDialog();
        }
    }, [commands, commandShellType]);

    React.useEffect(() => {
        if (consoleUri) {
            // the data we send to the agent should have a tokenType parameter separate from the cert in the header
            // the token we get from Portal has tokenType = "ssh-cert" attribute and "ssh-cert" in the beginning of the header
            // so we need to check that the tokenType is in the header and not a separate parameter and then split it out.
            if (!cert.tokenType && cert.header.includes(" ")) {
                cert.tokenType = cert.header.split(" ")[0];
            }
            cert.header = cert.header.split(" ")[1];
            const targetUri = consoleUri + '/ephemeralCert';
            const start = Date.now();

            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': accessToken,
                'Accept-Language': language
            };

            axios.post(targetUri, JSON.stringify({ token: cert }), { headers })
                .then(response => {
                    logger.clientRequest(RequestLoggerEnum.Post_Cert, {}, Date.now() - start, HttpMethod.Post, targetUri, "", "", "", 0, response.status, correlationId);
                }).catch(error => {
                    logger.clientRequest(RequestLoggerEnum.Post_Cert, {}, Date.now() - start, HttpMethod.Post, targetUri, "", "", "", 0, error.response.status, correlationId);
                    console.error('ACC.POST.CERT Something went wrong!', error);
                });
        }
    }, [cert]);

    //get the selected files from input element
    const selectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(e.target.files as FileList);
        if (!files) return;
        
        setKeepUploadInProgressDialogOpen(true);

        if (!hideUploadInProgressDialog || isUploadsInProgress) {
            //If there's already a upload request being processed
            //add the info of the new request to the current progress dialog
            filesToBeUploaded = filesToBeUploaded.concat(files);
            setUploadInProgressTotalCount(filesToBeUploaded.length);
        } else {
            //otherwise, there's no ongoing upload process
            filesToBeUploaded = [];
            tempFileCompleteStatusList = [];
            filesToBeUploaded = filesToBeUploaded.concat(files);
            if (!hideUploadDialog) {
                //if the previous upload complete dialog is still open
                //get rid of the open notification dialog and start a uploading process
                sendNewUploadRequestWhilePreivousDialogOpen = true;
                toggleUploadDialog(); //the callback function 'handleUploadsCompleteOnDismissed' will be called after the dialog is dimissed
            } else {
                //otherwise, there's no open dialog
                //start the uploading process
                setUploadInProgressTotalCount(filesToBeUploaded.length);
                uploadFiles();
            }
        }
        (e.target as HTMLInputElement).value = ''
    }

    const uploadFiles = () => {
        if (!filesToBeUploaded) return;
        //If there's a download click link dialog at the right bottom, close it
        setDownloadClickLinkDialogTrue();
        isUploadsInProgress = true;
        setPercentComplete(0);
        uploadSingleFile(0);
    }

    const uploadSingleFile = (fileIndex: number) => {
        if (fileIndex >= filesToBeUploaded.length) {
            isUploadsInProgress = false;

            setHideUploadInProgressDialogTrue(); // hide progress bar
            if (hideUploadInProgressDialog) {
                setHideUploadDialogFalse(); //show upload complete dialog
            }

            setUploadSucceedCount(tempSucceedCount);
            setFileCompleteStatusList(tempFileCompleteStatusList);

            sendNewUploadRequestWhilePreivousDialogOpen = false;
            setCallFileUploadFunction(false);
            return;
        }

        setCurrentFileIndex(fileIndex);
        const file = filesToBeUploaded[fileIndex];

        if (file.size > maxUploadSize) {
            const newFilesInfo: FilesInfo = {
                fileName: file.name,
                fileStatus: FileStatus.TooLarge
            }
            tempFileCompleteStatusList = [...tempFileCompleteStatusList, newFilesInfo];
            setUploadHasErrors(true);
            setFileTooLargeErrorExists(true);
            uploadSingleFile(fileIndex + 1);
            return; 
        }

        const formData = new FormData();
        formData.append("uploading-file", file, file.name);

        const url = consoleUri + "/terminals/" + termId + "/upload";

        const headers =  {
            'Authorization': accessToken,
        };

        const onUploadProgress = (evt: any) => {
            let quickUpload = true; //avoid flickering
            const progress = Math.round((evt.loaded / evt.total) * 100);
            setPercentComplete(progress);
            if (progress < 100 || !quickUpload) {
                setHideUploadInProgressDialogFalse();
                quickUpload = false;
            }
        }

        const options = {
            method: "POST",
            headers,
            data: formData,
            url,
            processData: false,
            contentType: false,
            onUploadProgress
        }

        FileManager
        .upload(options)
        .then((res) => {
            tempSucceedCount += 1;
            setUploadDestination(res.data.path);
            const newFilesInfo: FilesInfo = {
                fileName: file.name,
                fileStatus: FileStatus.Success
            }
            tempFileCompleteStatusList = [...tempFileCompleteStatusList, newFilesInfo];
        }).catch((err) => {
            setUploadHasErrors(true);
            setAllFilesTooLarge(false);
            const newFilesInfo: FilesInfo = {
                fileName: file.name,
                fileStatus: FileStatus.UnknownFailure
            }
            tempFileCompleteStatusList = [...tempFileCompleteStatusList, newFilesInfo];
        }).finally(() => {
            uploadSingleFile(fileIndex + 1);
        });
    }

    const addDownloadFileLinkInfo = (downloadFileLinkInfo: DownloadFileLinkInfo) => {
        setDownloadFileLinkInfoList(prevState => [...prevState, downloadFileLinkInfo]);
    }

    const ResetUserSettings = async () => {
        toggleResetUserDialog(); // hide dialog
        terminalRef.current?.resetUserTerminal(); 
    };

    const Restart = () => {
        toggleRestartDialog(); // hides the dialog screen
        terminalRef.current?.restartTerminal();
    };

    const ClosePort = () => {
        const targetUri = consoleUri + '/ports/' + currentPort + '/close';
        const headers =  {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': accessToken,
            'Accept-Language': language
        };

        axios.post(targetUri, JSON.stringify({}), { headers })
        .then(() => {
            setPort(0);
            addToLocalStorage(LocalStorageKey.CurrentOpenPort, '0');
        }).catch(error => {
            console.error('closeport', error);
        }); 
    };

    const OpenPort = (launch: boolean, portNum: number) => {
        const targetUri = consoleUri + '/ports/' + portNum + '/open';

        if (currentPort != 0 && currentPort != portNum) {
            ClosePort();
        }

        const headers =  {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': accessToken,
            'Accept-Language': language
        };

        let proxyLaunchPage: Window | null;
        
        if (launch) {
            proxyLaunchPage = window.open('', "_blank");
        }

        axios.post(targetUri, JSON.stringify({}), { headers })
        .then(response => {
            setPort(portNum);
            addToLocalStorage(LocalStorageKey.CurrentOpenPort, portNum.toString());
            if (launch) {
               if (proxyLaunchPage) {
                  const proxyUri = consoleUri + '/proxy/' + portNum + '/';
                  proxyLaunchPage.location.href = proxyUri;
               }
            }
        }).catch(() => {
            if (launch) {
                if (proxyLaunchPage) {
                  proxyLaunchPage.close();
                }
            }
        }); 
    };
    
    function handleFontSize(fontSize: string) {
        const userSettingFontSize = userSettingsState.properties.terminalSettings.fontSize;
        setFontSize(fontSize);
        if (userSettingFontSize != fontSize) {
            userSettingsDispatch({
                type: UserSettingsActionType.UpdateFontSize,
                payload: fontSize
            });
            const newUserSettings = {
                ...userSettingsState.properties,
                terminalSettings: {
                    ...userSettingsState.properties.terminalSettings,
                    fontSize: fontSize
                }
            }
            updateUserSettings(newUserSettings);
        }
    }

    function handleFontStyle(fontStyle: string) {
        const userSettingFontStyle = userSettingsState.properties.terminalSettings.fontStyle.toLowerCase();
        setFontStyle(fontStyle);
        if (userSettingFontStyle != fontStyle) {
            userSettingsDispatch({
                type: UserSettingsActionType.UpdateFontStyle,
                payload: fontStyle
            });
            const newUserSettings = {
                ...userSettingsState.properties,
                terminalSettings: {
                    ...userSettingsState.properties.terminalSettings,
                    fontStyle: fontStyle
                }
            }
            updateUserSettings(newUserSettings);
        }
    }

    function convertHeightToString (height: number) : string {
        return `${height}px`;
    }

    function handleEditorHeight (height: number): void {
        setEditorHeight(height);
    }

    function handleMaxHeight(): number {
        const editorAndTerminal = document.getElementById("editor-and-terminal");
        if(editorAndTerminal) {
            return editorAndTerminal.clientHeight;
        } else {
            return 0;
        }
    }

    // Callback functions for the toolbar
    const manageFileShare = () => {
        if(userSettingsState.properties.sessionType == SessionType.Mounted) {
            const storageProfile = userSettingsState.properties.storageProfile;
            postMessageHelper( PostMessageHelperType.OpenFileShare, '', storageProfile?.storageAccountResourceId, storageProfile?.fileShareName );
        }
    };

    const handleSwitchToV1ForEditor = (method: string) => {
        setSwitchToV1Method(method)
        showSwitchToV1();
    }

    const handleShowEditorWithFile = (filepath: string) => {
        setFileName(filepath.substring(filepath.lastIndexOf('/') + 1));
        setLocalFilepath(filepath);
        setOpenEditorWithFile(true);
    }

    const handleOpenEditor = () => {
        setEditorVisible(true);
    }

    return (
        // App return here
        <>
        { terminalVisible ? (
           <>
           <EditorVisibleContext.Provider value={{ editorVisible: editorVisible, setEditorVisible:setEditorVisible, openEditorWithFile: openEditorWithFile, setOpenEditorWithFile: setOpenEditorWithFile }}>
                <Stack verticalFill={true}>
                    <Stack.Item styles={{ root: { height: "37px" } }}>
                        <Toolbar
                            isRestarting={isRestarting}
                            consoleUri={consoleUri}
                            ClosePort={ClosePort}
                            currentPort={currentPort}
                            currentFontStyle={currentFontStyle}
                            currentFontSize={currentFontSize}
                            currentShellType={currentShellType}
                            currentTheme={sessionProps.currentTheme}
                            isFullScreen={isFullScreen}
                            manageFileShare={manageFileShare}
                            toggleDownloadDialog={toggleDownloadDialog}
                            togglePortDialog={togglePortDialog}
                            toggleResetUserDialog={toggleResetUserDialog}
                            toggleRestartDialog={toggleRestartDialog}
                            toggleSwitchShellTypeDialog={toggleSwitchShellTypeDialog}
                            toggleIsFullScreen={toggleIsFullScreen}
                            handleFontStyle={handleFontStyle}
                            handleFontSize={handleFontSize}
                            setTheme={sessionProps.setTheme}
                            selectFile={selectFile}
                            isToolbarFeatureDisabled={isToolbarFeatureDisabled}
                            isManageFileShareHidden={userSettingsState.properties.sessionType == SessionType.Ephemeral}
                            handleSwitchToV1ForEditor={handleSwitchToV1ForEditor}
                        />
                    </Stack.Item>
                    <Stack id="editor-and-terminal" verticalFill={true} style={{overflow: "hidden"}}>
                            <div style={{display: editorVisible ? 'block': 'none'}}>
                                <Stack.Item>
                                    <EditorWrapper 
                                        editorHeight={convertHeightToString(editorHeight)}
                                        consoleUri={consoleUri}
                                        rootDirectory={userRootDirectory}
                                        maxHeight={handleMaxHeight()}
                                        onEditorHeightChange={handleEditorHeight}
                                        displayFileName={fileName}
                                        displayFilepath={localFilepath}
                                        focusTerminal={() => setIsTerminalFocused(true)} />
                                </Stack.Item>
                            </div>
                            <Stack.Item grow shrink styles={{ root: { minHeight: "20px" } }}>
                                <CSTerminal
                                    currentFontStyle={currentFontStyle}
                                    currentFontSize={currentFontSize}
                                    currentShellType={currentShellType}
                                    setTerminalVisible={setTerminalVisible}
                                    toggleTermTimeoutDialog={toggleTermTimeoutDialog}
                                    setDownloadClickLinkDialogFalse={setDownloadClickLinkDialogFalse}
                                    setConsoleUri={setConsoleUri}
                                    setTermId={setTermId}
                                    setUserRootDirectory={setUserRootDirectory}
                                    setPort={setPort}
                                    ref={terminalRef}
                                    addDownloadFileLinkInfo={addDownloadFileLinkInfo}
                                    setIsToolbarFeatureDisabled={setIsToolbarFeatureDisabled}
                                    setVerifyStorageAccountError={sessionProps.setVerifyStorageAccountError}
                                    toggleErrorDialog={toggleErrorDialog}
                                    toggleConfirmReloadDialog={toggleConfirmReloadDialog}
                                    handleSwitchToV1ForEditor={handleSwitchToV1ForEditor}
                                    setIsRestarting={setIsRestarting}
                                    terminalHeight={editorVisible ? `${handleMaxHeight()-editorHeight}px` : "100%"}
                                    showEditorwithFile={(filepath: string) => handleShowEditorWithFile(filepath)}
                                    openEditor={handleOpenEditor}
                                    isTerminalFocused={isTerminalFocused}
                                    setIsTerminalFocused={setIsTerminalFocused}
                                />
                            </Stack.Item>
                    </Stack>
                </Stack>
                </EditorVisibleContext.Provider>

                <UploadDialog 
                    hideUploadDialog={hideUploadDialog}
                    toggleUploadDialog={toggleUploadDialog}
                    hideUploadInProgressDialog={hideUploadInProgressDialog}
                    percentComplete={percentComplete}
                    currentFileIndex={currentFileIndex}
                    fileCompleteStatusList={fileCompleteStatusList}
                    uploadDestination={uploadDestination}
                    uploadHasErrors={uploadHasErrors}
                    fileTooLargeErrorExists={fileTooLargeErrorExists}
                    allFilesTooLarge={allFilesTooLarge}
                    uploadSucceedCount={uploadSucceedCount}
                    uploadInProgressTotalCount={uploadInProgressTotalCount}
                    keepUploadInProgressDialogOpen={keepUploadInProgressDialogOpen}
                    setKeepUploadInProgressDialogOpen={setKeepUploadInProgressDialogOpen}
                    handleUploadCompleteDismissed={handleUploadCompleteDismissed}
                />

                <DownloadDialog
                    consoleUri={consoleUri}
                    termId={termId}
                    userRootDirectory={userRootDirectory}
                    downloadFileLinkInfoList={downloadFileLinkInfoList}
                    setDownloadFileLinkInfoList={setDownloadFileLinkInfoList}
                    hideDownloadDialog={hideDownloadDialog}
                    hideDownloadClickLinkDialog={hideDownloadClickLinkDialog}
                    toggleDownloadDialog={toggleDownloadDialog}
                    toggleDownloadClickLinkDialog={toggleDownloadClickLinkDialog}
                    setHideUploadDialogTrue={setHideUploadDialogTrue}
                />

                <PortDialog
                    hidePortDialog={hidePortDialog}
                    togglePortDialog={togglePortDialog}
                    OpenPort={OpenPort}
                />

                <ResetUserDialog
                    hideResetUserDialog={hideResetUserDialog}
                    toggleResetUserDialog={toggleResetUserDialog}
                    ResetUserSettings={ResetUserSettings} 
                />

                <SwitchShellTypeDialog
                    currentShellType={currentShellType}
                    hideSwitchShellTypeDialog={hideSwitchShellTypeDialog}
                    toggleSwitchShellTypeDialog={toggleSwitchShellTypeDialog}
                    ChangeShellType={ChangeShellType} 
                />

                <ConfirmReloadDialog
                    hideConfirmReloadDialog={hideConfirmReloadDialog}
                    toggleConfirmReloadDialog={toggleConfirmReloadDialog}
                    reloadTerminal={reloadTerminal}
                />

                <RestartDialog
                    hideRestartDialog={hideRestartDialog}
                    toggleRestartDialog={toggleRestartDialog}
                    Restart={Restart} 
                />

                <SwitchToV1 
                    showSwitchToV1={isSwitchToV1Open}
                    closeSwitchToV1={hideSwitchToV1}
                    switchToV1Method={switchToV1Method}
                />

            </>

        )
        :
        (            
            <>
                <div
                    id="terminal-dialog-back"
                    style={{ height: "100%", width: "auto", backgroundColor: "#000" }} 
                />
                    
                <TermTimeoutDialog
                    hideTermTimeoutDialog={hideTermTimeoutDialog}
                    toggleTermTimeoutDialog={toggleTermTimeoutDialog}
                    setTerminalVisible={setTerminalVisible}
                />

                <ErrorDialog
                    hideErrorDialog={hideErrorDialog}
                />
            </>
        )
        }
        </>
    );
};

export default Session;
