/*

######################
# Intermediate Mount #
######################

-   **Inputs**:
    -   `currentTheme`
        -   A theme object that contains the current colour variables
    -   `showIntermediateMount`
        -   A boolean variable for displaying or hiding the `IntermediateMount` component
    -   `setMountState`
        -   Advance or go back to a previous modal.
    -   `Quit`
        -   A call back function that can close the application
        -   It should be the same as `OSSelect`
-   **Purpose**: This component lets users select their setting in a first experience run.
-   **To do**:
    -   Add in dropdowns
    -   Backend communication
-   **Note**:
    -   This modal appears after `SubscriptionMount` and allows a user to select between
        -   Select existing
        -   Azure creates a storage account
        -   User creates a storage account
*/

// Imported packages
import * as React from "react";
import { useId } from "@fluentui/react-hooks";
import {
    mergeStyleSets,
    FontWeights,
    Modal,
    Stack,
    StackItem,
    Theme,
    Link,
    IChoiceGroupOption,
    ChoiceGroup,
    TooltipHost,
} from "@fluentui/react";
import { IconButton, PrimaryButton, DefaultButton } from "@fluentui/react/lib/Button";
import { useTranslation } from "react-i18next";
import jwt_decode from "jwt-decode";

// Handmade components
import { MountState, cloudShellStorageString } from "../../../common/consts";
import { useAccessTokenContext } from "../../DataProvider/EventProvider";
import { buildStorageFileShareName } from "../../Util/Utilities";
import { OnboardingInfo, UserLocation } from "../../../common/types";

// Interface components
interface IntermediateMountProps {
    currentTheme: Theme;
    userLocation: UserLocation;
    subscriptionId: string;
    showIntermediateMount: boolean; // variable to display or not
    setMountState: (state: MountState) => void; // call back function to advance mount state
    applyOnboardingInfo: (mountState: MountState, onboardingInfo: OnboardingInfo) => void;
    Quit: () => void; // call back function that can close the application
}

// Component
const IntermediateMount = (intermediateMountProps: IntermediateMountProps) => {
    const titleId = useId("Mount Storage");
    const { accessToken, puid } = useAccessTokenContext();
    const { t } = useTranslation();
    const contentStyles = mergeStyleSets({
        container: {
            maxWidth: 590,
        },
        header: [
            {
                flex: "1 1 auto",
                fontSize: 18,
                display: "flex",
                alignItems: "center",
                fontWeight: FontWeights.semibold,
                padding: "12px 5px 0px 24px",
            },
        ],
        body: {
            padding: "0 22px 20px 26px",
            overflowY: "hidden",
        },
    });

    const mountType = {
        select: "Select",
        autoCreate: "autoCreate",
        manualCreate: "manualCreate",
    };

    const mountOptions: IChoiceGroupOption[] = [
        {
            key: mountType.select,
            text: t("selectExistingStorageAccount"),
        },
        {
            key: mountType.autoCreate,
            onRenderField: (props, render) => (
                <>
                    {render!(props)}
                    <TooltipHost content={t("azureWillCreateStorageAccountForYouNote")}>
                        <IconButton
                            ariaLabel={t("azureWillCreateStorageAccountForYouNote")}
                            iconProps={{
                                iconName: "info",
                                style: {
                                    fontSize: "12px",
                                    height: "12px",
                                    width: "12px",
                                    color: intermediateMountProps.currentTheme.semanticColors
                                        .bodyText,
                                },
                            }}
                            styles={{
                                root: {
                                    color: "gray",
                                    height: "20px",
                                    selectors: {
                                        ":hover": {
                                            background: "none !important",
                                        },
                                    },
                                    width: "20px",
                                },
                            }}
                        />
                    </TooltipHost>
                </>
            ),
            text: t("autoCreateStorageAccount"),
        },
        {
            key: mountType.manualCreate,
            text: t("userCreateStorageAccount"),
        },
    ];
    const [selectedMountType, setMountType] = React.useState<string | undefined>(mountType.select);

    const onSessionTypeChange = React.useCallback((ev: any, option: any) => {
        setMountType(option.key);
    }, []);

    const buildOnboardingInfo = () => {
        const resourceGroupName = cloudShellStorageString + '-' + intermediateMountProps.userLocation.name;

        //storage account name restriction: 3-24 characters and must be lowercase alphanumeric
        let accountName = "cs" + intermediateMountProps.userLocation.code; 
        const len = 24 - accountName.length;
        accountName += puid.substring(0, len);
        accountName = accountName.toLowerCase().replace(/[^a-z|0-9]/g, 'x');

        const claims= jwt_decode(accessToken);
        const fileShareName = buildStorageFileShareName(claims, puid);

        const onboardingInfo = {
            location: intermediateMountProps.userLocation.name,
            resourceGroupID: '/subscriptions/' + intermediateMountProps.subscriptionId + '/resourcegroups/' + resourceGroupName,
            fileShareName: fileShareName,
            storageAccountName: accountName,
            diskSize: 5
        };

        return onboardingInfo;
    };

    // Handles OS Selection
    return (
        <Modal
            allowTouchBodyScroll={true}
            titleAriaId={titleId}
            isOpen={intermediateMountProps.showIntermediateMount}
            onDismiss={intermediateMountProps.Quit}
            isBlocking={true}
            containerClassName={contentStyles.container}
        >
            <div className={contentStyles.header}>
                <header id={titleId}>{t("mountStorageAccount")}</header>
                <IconButton
                    styles={{
                        root: {
                            marginLeft: "auto",
                            color: intermediateMountProps.currentTheme.semanticColors.bodyText,
                            marginRight: "2px",
                        },
                        rootHovered: {
                            // change close icon when hovered
                            color: intermediateMountProps.currentTheme.semanticColors
                                .bodyBackground,
                            background: "#E00B1C",
                        },
                    }}
                    iconProps={{ iconName: "Cancel" }}
                    ariaLabel={t("cancelButton")}
                    onClick={intermediateMountProps.Quit}
                />
            </div>

            <div className={contentStyles.body}>
                <p style={{marginBottom: 0, marginTop: '3px'}}>
                    {t("mountStorageAccountInfo")}{" "}
                    <Link href="https://aka.ms/CloudShell/docs/storage#securing-storage-access" target="_blank">
                        {t("learnMoreLink")}
                    </Link>
                </p>

                <Stack wrap tokens={{ childrenGap: "5", padding: "0px 0 20px 0 " }}>
                    <StackItem>
                        <ChoiceGroup
                            selectedKey={selectedMountType}
                            options={mountOptions}
                            onChange={onSessionTypeChange}
                        />
                    </StackItem>
                </Stack>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "left",
                    }}
                >
                    <PrimaryButton
                        onClick={() => {
                            if (selectedMountType === mountType.select) {
                                // if manual select
                                intermediateMountProps.setMountState(MountState.AdvancedMount);
                            } else if (selectedMountType === mountType.autoCreate) {
                                // if auto create a new RG
                                const onboardingInfo = buildOnboardingInfo();
                                intermediateMountProps.applyOnboardingInfo(MountState.IntermediateMount, onboardingInfo);
                            } else {
                                intermediateMountProps.setMountState(MountState.StorageCreation);
                            }
                        }}
                        text={t("nextButton")}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <DefaultButton
                        onClick={() => {
                            intermediateMountProps.setMountState(MountState.SubscriptionOnly);
                        }}
                        text={t("previousButton")}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default IntermediateMount;