import { IStyleFunctionOrObject } from "@fluentui/merge-styles";
import { IDialogStyleProps, IDialogStyles, mergeStyles } from "@fluentui/react";

//place the dialog in the right bottom position
export const dialogPositionRightBottom: IStyleFunctionOrObject<IDialogStyleProps, IDialogStyles> | undefined= {
    main: {
        position: 'absolute',
        bottom: '10px',
        right: '22px'
    }
};

//It's used for upload completion dialog and download click link dialog
export const uploadDownloadContentPropsStyles = {
    content: {
      minWidth: '380px',
      maxWidth: '95% !important',
      wordWrap: 'break-word', 
      overflowWrap: 'break-word', 
      selectors: {
        'p': {
          marginBottom: '0',
          marginTop:'0',
          marginLeft: '0',
        },
        //I used 400px here to satisfy the bug, but it could be as high as 955px to avoid a scrollbar
        '@media screen and (max-width: 400px)': {
            minWidth: '100%'
        }
      }
    },
    title: { 
        paddingBottom: '10px', 
        fontSize: '14px' 
    }
};

export const dialogFooterStyles = mergeStyles({
  display: 'flex',
  justifyContent: 'start',
  'button:first-child': {
    marginLeft: '-4px',
    marginRight: '12px'
  }
});

export const smallResolutionDialogStyles = {
  '@media screen and (max-width: 650px)': {
    width: '100%',
    maxHeight: '70%',
    overflowY: 'auto',
    position: 'relative' as any,
    top: '20%',
    transform: 'translateY(-50%)',
  }
};