/*
############
# Terminal #
############

-   **Inputs**: 
    -   `currentFontStyle`
        - Font for the terminal 
    -   `currentFontSize`
        - Font size
    -   `currentShellType`
        - Current shell type
    -   `setTerminalVisible`
        - React Hook to hide/show the terminal to users
    -   `toggleTermTimeoutDialog`
        - React Hook to toggle 'termTimeoutDialog'
    -   `setDownloadClickLinkDialogFalse`
        - React Hook to show download link click dialog
    -   `setTermId`
        - React Hook to set the terminal Id
    -   `setConsoleUri`
        - React Hook to set the console uri
    -   `setUserRootDirectory`
        - React Hook to set the user root directory for download
    -   `setPort`
        - React Hook to set current port for web preview
    -   `ref`
        - Expose Terminal's DOM to Seesion.tsx by ref
    -   `addDownloadFileLinkInfo`
        - Add the download url and download file name
  
-   **Purpose**: The `Terminal` component contains the themed `xterm` object that the user interacts with.
-   **Note**: None
*/

// External components
import axios, { AxiosError } from "axios";
import * as React from "react";
import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import { Terminal } from "@xterm/xterm";
import { AttachAddon } from "@xterm/addon-attach";
import { FitAddon } from "@xterm/addon-fit";
import { WebLinksAddon } from "@xterm/addon-web-links";
import { WebglAddon } from '@xterm/addon-webgl';
import { CanvasAddon } from '@xterm/addon-canvas';
import { CustomWebSocket, DownloadFileLinkInfo, TerminalRef, TerminalState } from "../../common/types";
import { BackgroundColors, ConnectionState, ProvisioningState, ControlSocketAudience, LocalStorageKey, NetworkType, ShellType, UserSettingsActionType, TerminalError, osTypeSelection, timeoutDuration, delta, TerminalPrompt, PostMessageHelperType, SwitchToV1MethodEnum, HttpMethod, RequestLoggerEnum, EditorOpenType } from "../../common/consts";
import { useAccessTokenContext, useParentMessageContext } from "../DataProvider/EventProvider";
import { getConsoleUri, getQueryParametersPrefix, getFromLocalStorage, guid, addToLocalStorage, getARMEndpoint, postMessageHelper, validateAudience, postCertMessageHelper, getFontSize, getFontStyle, isCloudNonProd } from "../Util/Utilities";
import { UserSettingsContext } from "../DataProvider/UserSettingsProvider";
import "./../../xterm/xterm.css";
import { useNonInitialEffect } from "../Util/useNonInitialEffect";
import { useTranslation } from "react-i18next";
import { CorrelationIdContext } from "../DataProvider/IdProviders";
import { useLogger } from "../Util/Logger";
import { useARMHelper } from "../Util/useARMHelper";
import { TelemetryLoggerEnum } from "../../common/consts";

// Interface attributes
interface TerminalProps {
    // These are the call back functions and data from parent
    currentFontStyle: string;
    currentFontSize: string;
    currentShellType: ShellType;
    setTerminalVisible: (terminalVisible: boolean) => void;
    toggleTermTimeoutDialog: () => void;
    setDownloadClickLinkDialogFalse: () => void,
    setTermId: (id: string | null) => void;
    setConsoleUri: (uri: string) => void;
    setUserRootDirectory: (userRootDirectory: string) => void;
    setPort: (currentPort: number) => void;
    ref: React.Ref<TerminalRef>;
    addDownloadFileLinkInfo: (downloadFileLinkInfo: DownloadFileLinkInfo) => void;
    setIsToolbarFeatureDisabled: (isToolbarFeatureDisabled: boolean) => void;
    setVerifyStorageAccountError: (verifyStorageAccountError: string) => void;
    toggleErrorDialog: () => void;
    toggleConfirmReloadDialog: () => void;
    handleSwitchToV1ForEditor: (method: string) => void;
    setIsRestarting: (isRestarting: boolean) => void;
    terminalHeight: string;
    showEditorwithFile:(filepath: string) => void;
    openEditor: () => void;
    isTerminalFocused: boolean;
    setIsTerminalFocused: (isTerminalFocused: boolean) => void;
}

// Variable declarations
const terminalState: TerminalState = {
  connectionTime: null,
  connectionState: null
};
let pingCount = 0;
let socketMessages = 0;
let activeSession = false;
let getTokenInterval: number | null | undefined = null;
let keepAliveID: number | ReturnType<typeof setTimeout> = 0;
let terminalSocket: null | CustomWebSocket;

// used for handling delayed resizing
let rtime: number;
let timeout = false;
let screenReaderMode: string;
let isTimedOut = false;

// Component
const CSTerminal = (terminalProps: TerminalProps, ref: React.Ref<TerminalRef>) => {
    const { t, i18n } = useTranslation();
    const language = i18n.language;
    const logger = useLogger(getARMEndpoint(), {});
    const XtermRef = useRef<Terminal | null>(null);
    const [isWaitingCursor, setIsWaitingCursor] = useState(false);
    const { accessToken, puid, tenantId } = useAccessTokenContext();
    const { commands, setCommands } = useParentMessageContext();
    const { userSettingsState, userSettingsDispatch } = React.useContext(UserSettingsContext);
    const [ divBackgroundColor, setDivBackgroundColor] = React.useState(terminalProps.currentShellType === ShellType.PowerShell ? BackgroundColors.PowerShell : BackgroundColors.Bash);
    const prevShellType = React.useRef<ShellType>(terminalProps.currentShellType);
    const networkTypeSelection = userSettingsState.properties.networkType;
    const { removeUserSettings } = useARMHelper();
   
    const { correlationId } = useContext(CorrelationIdContext);
    const fitAddon: FitAddon = new FitAddon();
    let attachAddon: AttachAddon;
    let termId: string | null;
    const consoleUriRef = useRef<string>("");
    let terminalIdleTimeout: number;
    let localCommands: string | null;

    function updateSize() {
      if (XtermRef.current) {
          fitAddon.fit();
      }
    }

    React.useImperativeHandle(ref, () => ({
      focus: () => {
        XtermRef.current?.focus();
      },
      restartTerminal: (msg?: string) => {
        restartTerminal(msg);
      },
      resetUserTerminal: () => {
        resetUserTerminal();
      },
      reloadTerminal: () => {
        closeTerminal(false);
        createTerminal();
      }
    }))

    function createTerminal() {
      const terminal_div = document.getElementById("terminal-container") as HTMLElement; //risky attachment, please check
      if (!terminal_div) return;
      while (terminal_div?.children.length) {
          terminal_div.removeChild(terminal_div.children[0]);
      }

      const term = new Terminal({
          cursorBlink: true,
          theme: { 
            background: terminalProps.currentShellType === ShellType.PowerShell ? BackgroundColors.PowerShell : BackgroundColors.Bash 
          },
      });
    
      term.onResize(function (size) {
        if (!termId) {
          return;
        }
  
        const targetUri = consoleUriRef.current + '/terminals/' + termId + '/size?cols=' + size.cols + '&rows=' + size.rows + '&version=2019-01-01';
        const start = Date.now();
        
        const headers =  {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': accessToken,
          'Accept-Language': language
        };
  
        axios.post(targetUri, JSON.stringify({}), { headers })
        .then(response => {
          logger.clientRequest(RequestLoggerEnum.Terminal_Resize, {}, Date.now() - start, HttpMethod.Post, targetUri, "", "", "", 0, response.status, correlationId);
        }).catch(error => {
          logger.clientRequest(RequestLoggerEnum.Terminal_Resize, {}, Date.now() - start, HttpMethod.Post, targetUri, "", "", "", 0, error.response.status, correlationId);
        }); 
      });
  
      term.loadAddon(fitAddon); // adds fitAddon (resize terminal to div) to terminal
      term.loadAddon(new WebglAddon());
      term.loadAddon(new CanvasAddon());

      term.open(terminal_div); // connects terminal to div
      
      //Todo: handle screenRaderMode
      try {
        screenReaderMode = window.localStorage.getItem("screenReaderMode") || "off";
      } catch (err) {
        screenReaderMode = "off";
        //todo: may add to kusto
      }
  
      term.options.screenReaderMode = false;
      window.term = term;

      term.focus();

      window.addEventListener("resize", updateSize);

      fitAddon.fit(); //initializes size
      term.loadAddon(new WebLinksAddon());
      terminalState.connectionState = ConnectionState.NotConnected;

      if (screenReaderMode === "on") {
        term.options.screenReaderMode = true;
      }

      XtermRef.current = term;

      term.attachCustomKeyEventHandler(keyboardHandler);

      provisionConsole();
  }

  function keyboardHandler(e: KeyboardEvent) {
    const keyCode = e.keyCode || e.which;
    // On Shift+Tab set focus to parent so keyboard-only users can navigate in and out of terminal
    //todo: the toolbar color does not look good, need to be fixed when work on the Toolbar
    if (e.key === 'Tab' || keyCode == 9) {
      if (e.shiftKey) {
        document.getElementById("terminal-container")?.focus();
        return false;
      }
    }
    
    if (e.ctrlKey || e.metaKey) {
      // On CTRL+C copy if there is an active selection, otherwise let xterm handle the command
      if (e.key === 'c' || e.key === 'C' || keyCode == 67) {
        if(XtermRef.current?.hasSelection()) {
          document.execCommand('copy');
          setTimeout(function () { XtermRef.current?.clearSelection() }, 20);
          return false;
        }
        return true;
      }

      //Todo: Handle Editor Keyboard Event
      // if (keyCode == 192) {
      //   if(isEditorOpen()) {
      //     editor.focus();
      //     return false;
      //   }
      //   return false;
      // }
    }

    // Todo: On CTRL+ALT+R toggle screen reader mode
    if ((e.ctrlKey || e.metaKey) && e.altKey && e.keyCode == 82 && e.type === "keydown") {
      screenReaderMode = screenReaderMode === "off" ? "on" : "off";
      try {
        window.localStorage.setItem("screenReaderMode", screenReaderMode);
      } catch (err) {
        screenReaderMode = "off";
      }

      if (XtermRef.current) {
        XtermRef.current.options.screenReaderMode = screenReaderMode === "on";
      }
      
      return false;
    }

    return true;
  }

  function keepAlive() {
    const start = Date.now();
    const targetUri = consoleUriRef.current + '/keepAlive';
  
    const headers =  {
      'Accept': 'application/json',
      'Authorization': accessToken,
      'Accept-Language': language,
      'Content-Type': 'application/json'
    };

    axios.post(targetUri, JSON.stringify({}), { headers })
    .then(response => {
      logger.clientRequest(RequestLoggerEnum.KeepAlive, {}, Date.now() - start, HttpMethod.Post, targetUri, "", "", "", 0, response.status, correlationId);
    }).catch(error => {
      logger.clientRequest(RequestLoggerEnum.KeepAlive, {}, Date.now() - start, HttpMethod.Post, targetUri, "", "", "", 0, error.response.status, correlationId);
    }); 
  }

  function provisionConsole() {
      //accFeatures example: &feature.azureconsole.providerlocation=eastus2&feature.azureconsole.cluster=123
      const accFeatures = Array.from(getQueryParametersPrefix('feature.azureconsole.'))
      .reduce((acc, [key, value]) => `${acc}&${key}=${value}`, '')
      .slice(0);

      const targetUri = getConsoleUri() + accFeatures;

      let start = Date.now();

      const userLocation= userSettingsState.properties.preferredLocation.replace(/ /g, "").toLowerCase();

      function provisionConsoleInternal(pollingTimeout?: Date) {
        start = pollingTimeout ? start : Date.now();
        let initialText = t("requestingCloudShell");
        
        if (networkTypeSelection == NetworkType.Isolated) {
          const virtualNetwork = getFromLocalStorage('virtualNetwork');
          initialText = "Requesting a Cloud Shell in virtual network" + (virtualNetwork ? (" '" + virtualNetwork + "'") : "") + ". "  + "This may take several minutes.";
        }

        XtermRef.current?.write(pollingTimeout ? "." : initialText);
        const method = pollingTimeout ? 'GET' : 'PUT';
        const data = {
            properties: {
              osType: osTypeSelection
            }
        };

        const startInternal = Date.now();
        
        const headers =  {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': accessToken,
            'x-ms-console-preferred-location': userLocation,
            'Accept-Language': language
          };
    
        axios({
          url: targetUri,
          method: method,
          headers: headers,
          data:(pollingTimeout ? undefined : JSON.stringify(data))
        })
        .then(response => {
          const errorMessage = "Sorry, your Cloud Shell failed to provision: the console deployment job failed. ";
          const action = "\x1B[1;31mPlease refresh the page. If the issue persists, please file a ticket " + '\x1b]8;;https://ms.portal.azure.com/#create/Microsoft.Support\x07New support request.\x1b]8;;\x07';
          
          if(response.data.properties.provisioningState === ProvisioningState.Succeeded) {
            logger.clientTelemetry(TelemetryLoggerEnum.PutConsole_Success, {}, {}, Date.now() - start);

            XtermRef.current?.writeln("\x1B[1;32mSucceeded.\x1B[0m ");
            XtermRef.current?.writeln('Connecting terminal...\n\r');
            connectTerminal(response.data, terminalProps.currentShellType);
            authorizeSession();
          } else if (response.data.properties.provisioningState === ProvisioningState.Failed) {
            terminalState.connectionState = ConnectionState.NotConnected;
            //todo: add logs 
            XtermRef.current?.writeln("\n\r\x1B[1;31" + errorMessage + action + "\x1B[1;31m" + "Request correlation id: " + response.request.getResponseHeader('x-ms-routing-request-id') + "\x1B[0m ");
          } else {
            const minutesToWait = networkTypeSelection === NetworkType.Isolated ? 10 : 5;
            pollingTimeout = pollingTimeout || new Date(Date.now() + minutesToWait * 60 * 1000);
            if (pollingTimeout > new Date()) {
              setTimeout(function () { provisionConsoleInternal(pollingTimeout) }, 1000);
            }

            else {
              terminalState.connectionState = ConnectionState.NotConnected;
              logger.clientTelemetry(TelemetryLoggerEnum.PutConsole_Timeout, {}, {}, Date.now() - start);
              XtermRef.current?.writeln("\n\r\x1B[1;31m" + errorMessage + action + "\x1B[1;31m" + "Request correlation id: " + response.request.getResponseHeader('x-ms-routing-request-id') + "\x1B[0m ");
            }
          }
        }).catch(error => {
          logger.clientRequest(method == 'GET' ? RequestLoggerEnum.Console_Get : RequestLoggerEnum.Console_Put, {}, Date.now() - startInternal, HttpMethod.Get, targetUri, "", "", "", 0, error.response.status, correlationId);
          const action = '\x1B[1;31mPlease refresh the page. If the issue persists, please follow the troubleshooting guide \x1b]8;;https://learn.microsoft.com/en-us/azure/cloud-shell/troubleshooting\x07Cloud Shell Troubleshooting\x1b]8;;\x07' + ' or file a ticket ' + '\x1b]8;;https://ms.portal.azure.com/#create/Microsoft.Support\x07New support request.\x1b]8;;\x07' + "\x1B[0m ";
          if (error.response.data && error.response.data.error) {
            XtermRef.current?.writeln("\x1B[1;31mSorry, your Cloud Shell failed to provision: " + JSON.stringify(error.response.data.error) + " " + action +  "\x1B[1;31m" + " Request correlation id: " + error.request.getResponseHeader('x-ms-routing-request-id') + "\x1B[0m ");
          }
          else {
            XtermRef.current?.writeln("\x1B[1;31mSorry, your Cloud Shell failed to provision.\x1B[0m " + action +  "\x1B[1;31m" +  "Request correlation id: " + error.request.getResponseHeader('x-ms-routing-request-id') + "\x1B[0m ");
          }
          logger.clientTelemetry(method == 'GET' ? TelemetryLoggerEnum.ConsoleGet_Failure : TelemetryLoggerEnum.ConsolePut_Failure, {}, {}, Date.now() - start);
      }).finally(() => {
        terminalProps.setIsRestarting(false);
      });
    }

    terminalState.connectionState = ConnectionState.Connecting;
    provisionConsoleInternal();
  }

  function connectTerminal(consoleResource: any, shellType: ShellType) {
    const start = Date.now();

    function connectTerminalInternal(consoleResource: any, retryCount?: number) {
      consoleUriRef.current = consoleResource.properties.uri;
      terminalProps.setConsoleUri(consoleUriRef.current);

      const targetUri = consoleUriRef.current + '/terminals?cols=' + XtermRef.current?.cols + '&rows=' + XtermRef.current?.rows + '&version=2019-01-01' + '&shell=' + shellType;
      const startInternal = Date.now();
      const requestId = guid();
  
      retryCount = retryCount || 0;
  
      const headers =  {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': accessToken,
        'x-ms-client-request-id': requestId,
        'Accept-Language': language
      };

      axios.post(targetUri, JSON.stringify({}), { headers })
      .then(response => {
        logger.clientTelemetry(TelemetryLoggerEnum.Terminal_Connect_Success, {}, { retryCount: retryCount }, Date.now() - start, correlationId);
        termId = response.data.id;
        terminalProps.setTermId(termId);
        terminalProps.setUserRootDirectory(response.data.rootDirectory);
        terminalIdleTimeout = response.data.idleTimeout || terminalIdleTimeout;
        terminalIdleTimeout = Number(terminalIdleTimeout);
        let socketUri = response.data.socketUri;
        socketUri = socketUri.replace(":443", "");

        // uri examples
        // targetUri            https://gateway03.centraluseuap.console.azure.com/n/cc-a3eab4b5/cc-a3eab4b5/terminals?cols=79&rows=30&version=2019-01-01&shell=bash
        // res.socketUri.good   wss://gateway03.centraluseuap.console.azure.com/n/cc-a3eab4b5/cc-a3eab4b5/terminals/2c413dae1e42bd66fb4f15919521194a
        // res.socketUri.bad    wss://localhost:3000//70b319b5fbc85cef8f46be71aab927d5
        const targetUriBody = targetUri.replace('https://','').split('?')[0];
        if (socketUri.indexOf(targetUriBody) == -1) {
          socketUri = 'wss://' + targetUriBody + '/' + termId;
        }
        if (targetUriBody.includes('servicebus')) {
          const targetUriBodyComponents = targetUriBody.split('/');
          socketUri = 'wss://' + targetUriBodyComponents[0] + '/$hc/' + targetUriBodyComponents[1] + '/terminals/' + termId;
        }
        
        connectSocket(socketUri, null, handleSocketOpen, handleTerminalSocketMessage);
        connectSocket(socketUri + "/control", null, handleControlSocketOpen, handleControlSocketMessage);
        
        document.dispatchEvent(new CustomEvent('layoutUpdate'));
        window.onresize = function () {
          rtime = Date.now();
          if (timeout === false) {
            timeout = true;
            setTimeout(resizeTerminal, delta);
          }
        }
      }).catch(error=> {
        logger.clientRequest(RequestLoggerEnum.Terminal_Post, { retryCount: retryCount }, Date.now() - startInternal, HttpMethod.Post, targetUri, "", "", "", 0, error.response.status, correlationId);
        if (error.response.status === 403 && error.response.data && error.response.data.error) {
          if (error.response.data.error.code === TerminalError.SubscriptionNotEnabled) {
            terminalState.connectionState = ConnectionState.NotConnected;
            deleteConsoleAndRedirectToOnboarding(error.response?.data.error.message, true);
            return;
          }
        }

        if (error.response.status === 400 && error.response.data && error.response.data.error) {
          if (error.response.data.error.code === TerminalError.TooManyTerminals) {
            terminalState.connectionState = ConnectionState.NotConnected;
            XtermRef.current?.writeln("\x1B[1;31mSorry, your Cloud Shell failed to request a terminal: " + error.response?.data.error.message + "\x1B[0m ");
            if (XtermRef.current) {
              XtermRef.current.options.cursorBlink = false;
            }
            return;
          }
          else if (error.response.data.error.code === TerminalError.UserSettingsInvalid) {
            //Note: usersettings will be deleted by agent
            deleteConsoleAndRedirectToOnboarding(error.response?.data.error.message, false);
            return;
          }
        }

        const action = '\x1B[1;31mPlease click the restart button. If the issue persists, please file a ticket \x1b]8;;https://ms.portal.azure.com/#create/Microsoft.Support\x07New support request.\x1b]8;;\x07' + "\x1B[0m ";

        if (error.response.statusText === 'error' && networkTypeSelection === NetworkType.Isolated) {
          terminalState.connectionState = ConnectionState.NotConnected;
          XtermRef.current?.writeln("\x1B[1;31mSorry, your Cloud Shell failed to request a terminal: " + action + "\x1B[1;31mTenant id: " + tenantId + "; \x1B[1;31mPUID: " + puid + "; \x1B[1;31mTimestamp: " + new Date().toISOString() + "\x1B[0m ");
          logger.clientTelemetry(TelemetryLoggerEnum.TerminalVnet_Failure, { status: error.response.status }, { retryCount: retryCount }, Date.now() - start);
          return;
        }

        if (retryCount! > 5) {
          terminalState.connectionState = ConnectionState.NotConnected;
          logger.clientTelemetry(TelemetryLoggerEnum.TerminalConnect_Timeout, { status: error.response.status }, { retryCount: retryCount }, Date.now() - start);
          if (error.response.data && error.response.data.error) {
            XtermRef.current?.writeln("\x1B[1;31mSorry, your Cloud Shell failed to request a terminal: " + JSON.stringify(error.response.data.error) + action + "\x1B[1;31mTenant id: " + tenantId + "; \x1B[1;31mPUID: " + puid + "; \x1B[1;31mTimestamp: " + new Date().toISOString() + "\x1B[0m ");
          }
          else {
            XtermRef.current?.writeln("\x1B[1;31mSorry, your Cloud Shell failed to request a terminal." + action + "\x1B[1;31mTenant id: " + tenantId + "; \x1B[1;31mPUID: " + puid + "; \x1B[1;31mTimestamp: " + new Date().toISOString() + "\x1B[0m ");
          }
        }
        else {
          setTimeout(function () { connectTerminalInternal(consoleResource, retryCount! + 1) }, 1000)
        }
      })
    }

    connectTerminalInternal(consoleResource);

  }

  function connectSocket(url: string, retryCount: number | null, handleSocketOpen: (((this: WebSocket, ev: Event) => any) | null), handleSocketMessage: (((this: WebSocket, ev: MessageEvent) => void) | null)) {
    retryCount = retryCount || 0;
    if (retryCount < 10) {
      const socket = new CustomWebSocket(url);
      socket.retryCount = retryCount + 1;
      socket.onopen = handleSocketOpen;
      socket.onerror = handleSocketConnectionError;
      socket.onmessage = handleSocketMessage;
    }
    else {
      if (!url.endsWith("/control")) {
        handleConnectionTimeout();
      }
    }
  }
  
  function authorizeSession() {
    const start = Date.now();
    const targetUri = consoleUriRef.current + "/authorize";
    const headers =  {
      'Accept': 'application/json',
      'Authorization': accessToken,
      'Accept-Language': language,
      'content-type':'application/json'
    };

    const options = {
      method: "POST",
      headers,
      data: JSON.stringify({}),
      url: targetUri,
      contentType: 'application/json',
    }

    axios(options)
    .then(response => {
      //Todo: should be logged to Kusto
      const cookieToken = response.data.token;
      const imgEle = document.createElement("img");
      imgEle.src = targetUri + "?token="+encodeURIComponent(cookieToken);
      logger.clientRequest(RequestLoggerEnum.Authorize, {}, Date.now() - start, HttpMethod.Post, targetUri, "", "", "", 0, response.status, correlationId);
    }).catch(error => {
      console.error('authorizeSession', error);
      logger.clientRequest(RequestLoggerEnum.Authorize, {}, Date.now() - start, HttpMethod.Post, targetUri, "", "", "", 0, error.response.status, correlationId);
    }); 
  }

  function handleSocketOpen(this: any, evt: Event) {
    this.onerror = handleSocketError;
    this.onclose = handleSocketClose;
    isTimedOut = false;
    attachAddon = new AttachAddon(this as CustomWebSocket);
    XtermRef.current?.loadAddon(attachAddon);
    XtermRef.current?.focus();

    getTokenInterval = getTokenInterval || window.setInterval(() => postMessageHelper(PostMessageHelperType.GetToken, ""), 1000 * 60 * 10);
    terminalState.connectionState = ConnectionState.Connected;
    terminalState.connectionTime = new Date();

    //Todo:
    logger.clientTelemetry(TelemetryLoggerEnum.Terminal_Open, {}, {}, Date.now() - terminalState.connectionTime.getTime());

    terminalProps.setIsToolbarFeatureDisabled(false);
    localCommands = commands;
    terminalSocket = this as CustomWebSocket;
    activeSession = true;

    socketMessages = 0;
    keepSocketAlive(this);
  }

  function writeInjectedCommands(socket: CustomWebSocket) {
    if(localCommands) {
      if (terminalProps.currentShellType === ShellType.PowerShell) {
        socket.send(localCommands.replaceAll('\n', '\r\n')); 
      }
      else {
        socket.send(localCommands);
      }
    }
    localCommands = null;
    setCommands('');
  }

  function keepSocketAlive(socket: CustomWebSocket) {
    if(socket.readyState == WebSocket.OPEN) {
      if((pingCount / 60) >= terminalIdleTimeout) {
        socket.close();
      }
      else {
        socket.send('');
        pingCount++;
        keepAliveID = setTimeout(function() {keepSocketAlive(socket)}, timeoutDuration);
      }
    }
  }
  
  const handleSocketClose = () => {
    activeSession = false;
    if (terminalState.connectionState === ConnectionState.Connected) {
      const socketOpenDuration = Date.now() - terminalState.connectionTime!.getTime(); //check if it's null
      console.log("Socket closed after " + socketOpenDuration + " milliseconds, pingCount is at " + pingCount + ", terminalIdleTimeout is " + terminalIdleTimeout + ", socketMessages is " + socketMessages);
      logger.clientTelemetry(TelemetryLoggerEnum.TerminalSocket_Close, {}, { pingCount : pingCount, terminalIdleTimeout: terminalIdleTimeout, socketMessages: socketMessages }, socketOpenDuration);
      closeTerminal(false);
      cancelSocketAlive();
      terminalProps.setTerminalVisible(false);
      if (getTokenInterval) {
        window.clearInterval(getTokenInterval);
        getTokenInterval = null;
      }

      isTimedOut = true;
      terminalSocket = null;
      
      terminalProps.toggleTermTimeoutDialog();
    }
  }

  function handleControlSocketMessage(evt: MessageEvent) {
    const reader = new FileReader();
    if (evt.data instanceof Blob) {
      reader.onload = function(evt: ProgressEvent<FileReader>){
        handleControlSocketJSON(JSON.parse(reader.result as string));
        return;
      }
      reader.readAsText(evt.data);
    }
    else {
      handleControlSocketJSON(JSON.parse(evt.data));
    }
  }

  //Todo: finish handleControlSocketJSON function
  function handleControlSocketJSON(msg: any) {
    const audience = msg.audience;
    if (audience === ControlSocketAudience.Download) {
      const downloadUrl = consoleUriRef.current + msg.fileUri;

      const downloadFileLinkInfo: DownloadFileLinkInfo = {
        downloadClickUrl: downloadUrl,
        fileName: msg.filepath.substring(msg.filepath.lastIndexOf('/') + 1),
      };

      terminalProps.addDownloadFileLinkInfo(downloadFileLinkInfo);
      //show the download link dialog
      terminalProps.setDownloadClickLinkDialogFalse();
    }
    if (audience === ControlSocketAudience.Editor) {
      if(isCloudNonProd()) {
        if(typeof msg.fileName !== "undefined"){
          logger.clientTelemetry(TelemetryLoggerEnum.Editor_Open, { triggerType: EditorOpenType.TerminalWithFileName }, {}, 0);
          terminalProps.showEditorwithFile(msg.fileUri)
        } else {
          logger.clientTelemetry(TelemetryLoggerEnum.Editor_Open, { triggerType: EditorOpenType.Terminal }, {}, 0);
          terminalProps.openEditor();
        }
      } else {
        terminalProps.handleSwitchToV1ForEditor(SwitchToV1MethodEnum.EditorCommand);
      }
    }
    if (audience === ControlSocketAudience.Token) {
      const endpoint = validateAudience(msg.tokenAudience);
      if (endpoint) {
        logger.clientTelemetry(TelemetryLoggerEnum.Token_Send, { "audience": endpoint, "puid": puid }, {}, 0);
        postMessageHelper(PostMessageHelperType.GetToken, endpoint);
      }
      else {
        logger.clientTelemetry(TelemetryLoggerEnum.Token_Invalid, { "audience": endpoint, "puid": puid }, {}, 0);
        console.error("Audience '" + endpoint + "' cannot be handled.");
      }
    }
    if (audience === ControlSocketAudience.Cert) {
      postCertMessageHelper(msg.sshTokenData);
    }
    if (audience === ControlSocketAudience.Url) {
      const url = msg.url;
      console.log("audience：url")
    }
  }

  async function closeTerminal(disposeTerminal: boolean) {
    if (terminalState.connectionTime) {
      logger.clientTelemetry(TelemetryLoggerEnum.Terminal_Close, {}, {}, Date.now() - terminalState.connectionTime.getTime());
    }

    terminalProps.setIsToolbarFeatureDisabled(true);

    termId = null;
    terminalProps.setTermId(termId);
    
    //userSettings = null;
    //storage = {};
    terminalState.connectionState = ConnectionState.NotConnected;
    terminalState.connectionTime = null;
    terminalProps.setPort(0);
    addToLocalStorage(LocalStorageKey.CurrentOpenPort, '0');
    if (disposeTerminal) {
      XtermRef.current?.dispose();
    }
  }

  function cancelSocketAlive() {
    if (keepAliveID) {
      clearTimeout(keepAliveID);
      pingCount = 0;
    }
  }

  function handleSocketError(evt: Event) {
    console.error("Socket Error: " + JSON.stringify(evt));
  }

  function handleConnectionTimeout() {
    closeTerminal(false);

    XtermRef.current?.writeln("\n\r\x1B[1;31mSorry, your Cloud Shell failed to connect terminal: websocket cannot be established. Press \"Enter\" to reconnect.\x1B[0m ");
    XtermRef.current?.attachCustomKeyEventHandler(reconnectOnEnterKeydown);
  }

  function reconnectOnEnterKeydown(e: KeyboardEvent) {
    if (e.keyCode === 13 || e.key === 'Enter') {
      XtermRef.current?.attachCustomKeyEventHandler(keyboardHandler);
      postMessageHelper(PostMessageHelperType.GetToken, "");
      return false;
    }
    return true;
  }

  function handleSocketConnectionError(this: any, event: Event) {
    console.error("Socket Connection Error: " + JSON.stringify(event));
    const that = this as CustomWebSocket;

    window.setTimeout( () => {
      connectSocket(that.url, that.retryCount!, that.onopen, that.onmessage);
    }, 500);
  }

  function handleTerminalSocketMessage(this: any, e: MessageEvent) {
    pingCount = 0;
    socketMessages++;
    const content = new TextDecoder().decode(e.data as ArrayBuffer);
    if (content.includes(TerminalPrompt.PowerShell) || content.includes(TerminalPrompt.Bash) && localCommands && localCommands.length > 0) {
      writeInjectedCommands(this);
    }
  }

  function handleControlSocketOpen(this: any) {
    this.onerror = handleSocketError;
    this.onclose = handleControlSocketClose;
  }

  function handleControlSocketClose(this: any) {
    if (terminalState.connectionState === ConnectionState.Connected || terminalState.connectionState === ConnectionState.Connecting) {
      const that = this.socket;
      window.setTimeout(function () {
        connectSocket(that.url, 0, that.onopen, that.onmessage);
      }, 500);
    }
  }

  function resizeTerminal() {
    if (Date.now() - rtime < delta) {
      setTimeout(resizeTerminal, delta);
    }
    else {
      timeout = false;
      document.dispatchEvent(new CustomEvent('layoutUpdate'));
    }
  }

  async function restartTerminal(msg?: string) {
    terminalProps.setIsRestarting(true);

    if (msg) {
      XtermRef.current?.writeln('\r' + msg + '\r\n');
      fitAddon.fit();
    }
    XtermRef.current?.write('\r' + t('restartingCloudShellMsg'));

    setIsWaitingCursor(true);

    closeTerminal(false);
  
    XtermRef.current?.clear();

    await deleteConsole();
    setIsWaitingCursor(false);
    createTerminal();
  }

  function switchTerminal(msg?: string) {
    if (accessToken != "" && prevShellType.current != terminalProps.currentShellType) {
      prevShellType.current = terminalProps.currentShellType;
      restartTerminalInternal(
        t("switchingCloudShell"),
        function (setTimeout: (arg0: number) => void) {
          setTimeout(500);
        },
        msg
      );
    }
  }

  function restartTerminalInternal(consolemsg: string, initialize: (setTimeout: (arg0: number) => void) => void, msg?: string) {
    if (msg) {
      XtermRef.current?.writeln('\r' + msg + '\r\n');
      fitAddon.fit();
    }
    XtermRef.current?.write('\r' + consolemsg);

    closeTerminal(false);
  
    XtermRef.current?.clear();

    waitingCursor(
      initialize,
      function () {
        setDivBackgroundColor(terminalProps.currentShellType === ShellType.PowerShell ? BackgroundColors.PowerShell : BackgroundColors.Bash)
        createTerminal();
      }
    );
  }

  function deleteConsole() {
    const targetUri = getConsoleUri();
    const start = Date.now();
    
    const headers =  {
      'Accept': 'application/json',
      'Authorization': accessToken,
      'Accept-Language': language
    };

     const data = JSON.stringify({});

     return axios.delete(targetUri, { data, headers })
        .then((res) => {
          return res;
        })
        .catch((err) => {
          logger.clientRequest(RequestLoggerEnum.Terminal_Restart, {}, Date.now() - start, HttpMethod.Delete, targetUri, "", "", "", 0, err.response.status, correlationId);
          throw err;
        })
  }

  async function resetUserTerminal() {
    XtermRef.current?.write('\r' + t("resettingUserSettings"));
    XtermRef.current?.clear();
    await closeTerminal(false);
    try {
      await Promise.all([removeUserSettings(), deleteConsole()])
      userSettingsDispatch({
        type: UserSettingsActionType.DeleteUserSettings
      });
    } catch (error) {
      //show error dialog
      terminalProps.setTerminalVisible(false);
      terminalProps.toggleErrorDialog();
    }
  }

  async function deleteConsoleAndRedirectToOnboarding(errorMsg: string, deleteUserSettings: boolean) {
      try {
        await deleteConsole();
        if(deleteUserSettings) {
          await removeUserSettings();
        }
        userSettingsDispatch({
          type: UserSettingsActionType.DeleteUserSettings
        });
        terminalProps.setVerifyStorageAccountError(errorMsg);
      } catch (err) {
        XtermRef.current?.write("\x1B[1;31m" + errorMsg + "\x1B[0m");
      }
  }

  function waitingCursor(initialize: (setTimeout: (arg0: number) => void) => void, done: () => void) {
    const printCursor = function () {
      let index = 0;
      const chars = ['-', '\\', '|', '/'];
      return window.setInterval(function () {
        XtermRef.current?.write("\x1B[1D" + chars[index++ % chars.length]);
      }, 200);
    }();
  
    initialize(function (timeout: number) {
      window.setTimeout(
        function () { window.clearInterval(printCursor); if (done) done(); },
        timeout || 500);
    });
  }

  useEffect(() => {
    if (!isWaitingCursor) return;
      
    let index = 0;
    const chars = ['-', '\\', '|', '/'];

    const interval = setInterval(() => {
      XtermRef.current?.write("\x1B[1D" + chars[index++ % chars.length]);
    }, 200);

    return () => clearInterval(interval); 
  }, [isWaitingCursor]);

  React.useEffect(() => {
    if (accessToken != "") {
      if (!XtermRef.current || isTimedOut) {
        createTerminal();
      } 
    }
  }, [accessToken]);

  React.useEffect(() => {
    if (accessToken != "") {
      if (terminalState.connectionState === ConnectionState.Connected) {
        window.setTimeout(keepAlive, 500);
      }
    }
  }, [terminalState.connectionState, accessToken]);

  React.useEffect(() => {
      // executes once div created
      if (XtermRef.current && XtermRef.current.options) {
        XtermRef.current.options.fontSize = getFontSize(terminalProps.currentFontSize);
        updateSize();
      }
  }, [terminalProps.currentFontSize]); //change when font size changes

  React.useEffect(() => {
      // Update font size on change
      // executes once div created
      if (XtermRef.current && XtermRef.current.options) {
        XtermRef.current.options.fontFamily = getFontStyle(terminalProps.currentFontStyle);
      
        updateSize();
        window.dispatchEvent(new Event("resize"));
      }
  }, [terminalProps.currentFontStyle]); // change when font style changes

  React.useEffect(() => {
    if(commands && terminalState.connectionState === ConnectionState.Connected) {
        terminalProps.toggleConfirmReloadDialog();
    }
}, [commands]);

  React.useEffect(() => {
    getTerminalHeight();
  }, [terminalProps.terminalHeight]);

  useNonInitialEffect(switchTerminal, [terminalProps.currentShellType, accessToken]) // call switchTerminal when current OS changes after initial render

  function getTerminalHeight() {
    const terminalContainer = document.getElementById("terminal-container");
    if(terminalContainer) {
      terminalContainer.style.height = terminalProps.terminalHeight + "px";
    }
    window.dispatchEvent(new Event("resize"));
  }

  React.useEffect(() => {
    if (terminalProps.isTerminalFocused) {
      console.log("focus terminal");
      XtermRef.current?.focus();
      terminalProps.setIsTerminalFocused(false); //reset the flag
    }
  }, [terminalProps.isTerminalFocused, terminalProps.setIsTerminalFocused]);

  return (
    <>
      <div
        id="terminal-container"
        data-testid="terminal-container"
        style={{ 
          height: "100%", 
          width: "auto",
          backgroundColor: divBackgroundColor,
          paddingLeft: '3px'
        }} 
        //Todo: handle accessibility
        aria-label={t("screenReaderOffMessage")}
        // screen-reader-on-message="Enter CTRL-ALT-R to turn off screen reader support"
        // screen-reader-off-message="Enter CTRL-ALT-R to turn on screen reader support"
      ></div>
    </>
  );
};

export default forwardRef(CSTerminal);
