import { allowedCommandsForInjection } from "../../common/consts";
import { CommandInjectionBody, Command, CommandArg } from "../../common/types";

export function handleCommandEvtBody(commandBody: CommandInjectionBody) {
    let fullCommand = "";
    if(Array.isArray(commandBody)){
        commandBody.forEach(command => {
            fullCommand = fullCommand + unpackCommand(command);
        });
    }
    else {
        fullCommand = fullCommand + unpackCommand(commandBody.command as Command);
    }
    return fullCommand;
}

function unpackCommand(command: Command) {
    const name = command.name;
    if (!allowedCommandsForInjection.includes(name)) {
        console.error(name + " is not supported in command injection");
        return "";
    }
    const args = command.args;
    const output = name + " ";
    let fullArgs = "";
    if (args) {
        if (Array.isArray(args)) {
            args.forEach(arg => {
                if (fullArgs.length > 0) {
                    fullArgs = fullArgs + " ";
                }
                fullArgs = fullArgs + unpackArgs(arg);
            });
        }
        else {
            fullArgs = unpackArgs(args);
        }
    }
    return output + fullArgs + "\n";
}

function unpackArgs(arg: CommandArg) {
    if(!arg) {
        return "";
    }
    const prop = arg.prop;
    const value = arg.value;
    if (prop && value) {
        return sanitizeArg(prop) + " " + sanitizeArg(value);
    } else if(prop) {
        return sanitizeArg(prop);
    } else if(value) {
        return sanitizeArg(value);
    }
    else {
        return "";
    }
}

function sanitizeArg(command: string) {
    if(command === '' || command.match("^[A-Za-z0-9._\\\\-]+$") || command.match("^>+$")) {
        return command;
    }
    else {
        // escape special characters with a preceding slash: & | ; $ > < \ ! ' " ( ) `
        const escapedQuotes = command.replace(/[&|;$><\\!'"()`]/g, "\\$&").trim();
        return "\"" + escapedQuotes + "\"";
    }
}